<template>
  <div class="h-full flex flex-col">
    <div class="flex gap-2 xl:gap-6 shadow z-20 items-stretch">

      <NuxtLink to="/admin" class=" flex items-center justify-center  p-4 xl:p-6">
        <img alt="logo" src="/logo.svg" class="max-h-[50px] w-auto"/>
      </NuxtLink>
      <div v-if="mainTabs.length>0" class="grow flex h-full w-full justify-center items-stretch relative">

        <div v-for="tab in mainTabs" class="h-full items-stretch xl:flex hidden ">
          <NuxtLink :to="tab.href"
                    class="border-b-4 flex items-center justify-stretch p-6 cursor-pointer transition hover:bg-light"
                    :class="[tab.current ? 'border-primary text-primary' : 'border-b-white hover:border-b-light']">
            {{ tab.name }}
          </NuxtLink>
        </div>


        <div @click="tabsOpen=true"
             class="flex xl:hidden border-b-4 h-full items-center justify-stretch p-6 cursor-pointer  border-primary text-primary relative">
          <div class="flex items-center gap-2">
            {{ mainTabs.find(t => t.current)?.name }}
            <i class="fal fa-angle-down"/>
          </div>
          <Transition>
            <div v-if="tabsOpen" v-on-click-outside="()=>tabsOpen=false"
                 class="absolute w-full bg-white shadow border rounded left-0 translate-y-full">
              <div class="">
                <div v-if="mainTabs" v-for="link in mainTabs.filter(c=>!c.current)">
                  <NuxtLink :to="link.href" class="px-4 block py-2 hover:bg-light transition">
                    {{ link.name }}
                  </NuxtLink>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>

      <div v-else class="grow"></div>
      <div class="whitespace-nowrap flex justify-center items-center cursor-pointer  p-4 xl:p-6"
           v-if="useUser().pageRole === 'admin'">
        <div @click="userNavigationOpen = !userNavigationOpen" class="relative">
          <div class="hidden xl:flex items-center gap-2">
            <div class="hidden xl:block">{{ user.user?.given_name }} {{ user.user?.family_name }}</div>
            <i class="fal fa-angle-down transition hidden xl:block" :class="userNavigationOpen ? 'rotate-180' : ''"></i>
          </div>
          <div class="flex xl:hidden items-center gap-2">
            <i class="fal fa-user"></i>
          </div>
          <Transition>
            <div v-on-click-outside="()=>userNavigationOpen=false" v-if="userNavigationOpen"
                 class="absolute top-8 right-0 bg-white shadow border rounded">
              <div class="">
                <div v-for="link in userNavigation">
                  <NuxtLink :to="link.href" class="px-4 block py-2 hover:bg-light transition">
                    {{ $t(link.name) }}
                  </NuxtLink>
                </div>
              </div>
            </div>
          </Transition>

        </div>
      </div>
      <div class="xl:hidden p-4 xl:p-6 flex justify-center items-center cursor-pointer" @click="overlayMenu=true">
        <i class="fal fa-bars text-xl"></i>
      </div>
    </div>


    <div class="flex grow overflow-y-auto">


      <div class="shadow overflow-y-auto z-10 hidden xl:block min-w-[300px] max-w-[300px]">
        <slot name="header"></slot>
      </div>

      <main class="max-w-full bg-page flex-1 h-full relative">
        <Notifications></Notifications>

        <div class="p-4 xl:p-6 h-full overflow-y-auto">
          <slot/>
        </div>
      </main>
    </div>


    <div class="absolute top-0 left-0 right-0 bottom-0 z-50 "
         :class="overlayMenu ? 'bg-black/20' : 'bg-black/0 pointer-events-none'" @click.self="overlayMenu=false">

      <div v-on-click-outside="()=>overlayMenu=false"
           class="bg-white overflow-y-auto h-full w-max-full w-[285px] shadow-3xl right-0 absolute transition"
           :class="overlayMenu ? 'translate-x-0' : 'translate-x-full'">
        <div class="flex justify-end items-center p-6 cursor-pointer" @click="overlayMenu=false">
          <i class="fal fa-xmark text-xl"></i>
        </div>
        <slot name="header"></slot>
      </div>

    </div>


  </div>
</template>

<script setup lang="ts">

import {useUser} from '@/stores/UserStore'
import {useSurveyStore} from "~/stores/SurveyStore";
import {vOnClickOutside} from '@vueuse/components'

const user = useUser()

const tabsOpen = ref(false)
const overlayMenu = ref(false)
const userNavigationOpen = ref(false)
const userNavigation = [{name: 'logout', href: '/logout'}, {name: 'create_account', href: '/admin/create-account'}]

const mainTabs = computed(() => {
  const survey = useSurveyStore().survey
  if (survey && survey.uuid && useRouter().currentRoute.value.fullPath.indexOf(`/admin/`) >= 0) {
    return [
      {
        name: 'Création',
        href: `/admin/survey/${survey.uuid}/creation`,
        current: useRouter().currentRoute.value.fullPath.indexOf(`/admin/survey/${survey.uuid}/creation`) >= 0
      },
      {
        name: 'Diffusion',
        href: `/admin/survey/${survey.uuid}/diffusion`,
        current: useRouter().currentRoute.value.fullPath.indexOf(`/admin/survey/${survey.uuid}/diffusion`) >= 0
      },
      {
        name: 'Résultats',
        href: `/admin/survey/${survey.uuid}/results`,
        current: useRouter().currentRoute.value.fullPath.indexOf(`/admin/survey/${survey.uuid}/results`) >= 0
      },
    ]
  }
  return [];
})
</script>
